import {
  faChild,
  faHeartBroken,
  faHome,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"

export default function OurServices() {
  return (
    <Container className="p-4 text-center">
      <h1 className="fw-bold h4">Our Services</h1>
      <p>
        We provide a range of legal services including divorce, child
        custody, paternity, child support, and adoption.
      </p>
      <Row>
        <Col lg={6}>
          <Row className="h-100">
            <Col className="p-md-3" md={6}>
              <Card className="h-100 bg-light-green" text="dark">
                <Card.Body>
                  <FontAwesomeIcon
                    className="text-secondary"
                    icon={faHeartBroken}
                    size="6x"
                  />
                  <Card.Title className="mt-2">Divorce</Card.Title>
                  <Card.Text>
                    <ul className="d-inline-block text-left">
                      <li>High Asset</li>
                      <li>Contested</li>
                      <li>Uncontested</li>
                      <li>Same Sex</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mt-3 mt-md-0 p-md-3" md={6}>
              <Card bg="secondary" className="h-100">
                <Card.Body>
                  <FontAwesomeIcon
                    className="text-light-green"
                    icon={faMoneyCheck}
                    size="6x"
                  />
                  <Card.Title className="mt-2">
                    Paternity and Child Support
                  </Card.Title>
                  <Card.Text>
                    We handle all paternity and child support matters.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <Row className="h-100">
            <Col className="mt-3 mt-md-0 p-md-3" md={6}>
              <Card className="h-100 bg-light-green" text="dark">
                <Card.Body>
                  <FontAwesomeIcon
                    className="text-secondary"
                    icon={faChild}
                    size="6x"
                  />
                  <Card.Title className="mt-2">Child Custody</Card.Title>
                  <Card.Text>
                    We can help you obtain or modify a child custody order.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mt-3 mt-md-0 p-md-3" md={6}>
              <Card bg="secondary" className="h-100">
                <Card.Body>
                  <FontAwesomeIcon
                    className="text-light-green"
                    icon={faHome}
                    size="6x"
                  />
                  <Card.Title className="mt-2">Adoption</Card.Title>
                  <Card.Text>
                    Looking to adopt? We can help with that too!
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
