import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGavel } from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Jumbotron } from "react-bootstrap"
import * as styles from "./index.module.css"
import Layout from "../../components/layout"
import OurServices from "../../components/our-services"

export default function LegalServices() {
  return (
    <Layout>
      <Jumbotron className="bg-secondary mb-0 p-4 text-center" fluid>
        <FontAwesomeIcon
          className="text-light-green"
          icon={faGavel}
          size="6x"
        />
        <h1 className="fw-bold h2 mt-4">
          Legal Services That Are{" "}
          <span className="text-light-green">Truly</span> Different
        </h1>
      </Jumbotron>
      <OurServices />
      <Container className="bg-secondary mb-0 p-4 p-md-5">
        <h1 className="fw-bold h4 text-light-green">
          Navigating the Legal Challenges Presented by Evolving Definitions of
          the Family
        </h1>
        <p className="mt-4">
          The divorce rate in the United States is the highest in the world.
          Well over fifty percent of all marriages end in divorce. Sixty seven percent of all
          second marriages end in divorce. The divorce process is often
          associated with an increase in anxiety, stress and/or depression
          resulting from the loss of one’s spouse, child(ren), stability and
          lifestyle. Fifty percent of all children living in the United States today are
          children of divorce. Twenty eight percent of all children living in this country are
          born of never married parents. The issues being decided by our
          domestic relations courts have become increasingly more complex in
          recent years. This trend is expected to continue as the law evolves to 
          define the modern family.
        </p>
        <h2 className="h5 text-light-green">A Team To Get You Through It</h2>
        <p className="mt-3">
          We handle all types of divorce and child custody matters from
          uncontested divorce to complex contested matters involving
          substantial assets and business valuation/division; contested child
          custody matters (including UCCJEA multi-jurisdictional custody
          litigation); paternity; child support arrearage/modification;
          visitation; and adoption. By their very nature, family law issues are
          among the most sensitive and potentially life-changing of all legal
          issues. Attorney fees in hotly contested proceedings can be a
          significant expense. However, the financial reality of divorce demands
          that you seek the best legal representation and advice that you can
          afford. The failure to do so can result in much greater expense than
          you ever imagined.
        </p>
        <div className="mt-4 text-center">
          <StaticImage
            alt="the team"
            className="border-black"
            placeholder="blurred"
            src="../../images/legalservices.jpg"
            width="600"
          />
        </div>
        <p className="mt-4">
          We have handled literally thousands of divorces and child custody
          cases for well over three decades. Some have involved contested marital
          assets valued at many millions of dollars. Others have involved contested
          child custody and unique legal issues arising under complex statutory
          law such as the UCCJEA (Uniform Child Custody Jurisdiction Enforcement
          Act).
          However, the one common thread which has run through each and every
          one of our many family law cases is the fact that there were people
          (often little children) whose lives were affected by the
          representation and advice that we provided to our clients. Going
          through the divorce process can be a stressful experience. You should
          feel confident that your divorce attorney possesses the experience and
          resources necessary to represent you personally and effectively.
        </p>
        <h2 className="h5 text-light-green">A word from Mark</h2>
        <figure className="bg-dark mt-3 p-3 p-lg-4">
          <blockquote className={`blockquote ${styles.quoteText}`}>
            <p>
              "I take great pride in the fact that the majority of our new
              clients come to us from the recommendation of our former and
              existing clients and friends. This, to me, is our greatest measure
              of success. We take our responsibility to provide you with the
              best possible representation very seriously. The reputation that
              we have built for this law practice depends upon it."
            </p>
            <p>
              Here at Mark Sterling Gober, LLC, we want to be more than just your
              lawyer.
            </p>
          </blockquote>
          <figcaption className="blockquote-footer">Mark Gober</figcaption>
        </figure>
        <p className="mt-3 mb-0">
          Mark Sterling Gober, LLC is committed to providing legal services
          competitive with those services offered by much larger and more
          impersonal firms. At Mark Sterling Gober, LLC, we possess the
          expertise, resources and relationships necessary to provide our
          clients with personal legal representation which is second to none. We
          understand the importance of giving our undivided personal attention
          to each and every individual and/or business whose representation we
          undertake. It is upon this philosophy that our practice has been
          built. The people of Tuscaloosa have been incredible in their
          overwhelming response to our local practice since it was established
          in early 2005. By this, we are humbled.
        </p>
      </Container>
    </Layout>
  )
}
